<template>
  <div class="ied-bluetooth">
    <app-header :title="title" :isShowBack="true"></app-header>
    <div>
      <app-overview :title="type ? '// 蓝牙网关 //' : '// 中央控制器 //'" :list="detialsList"></app-overview>
      <app-list :opt="opt" @get="onGet"></app-list>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    // let _this=this,
    return {
      // isLoading: true,
      type: 0, // 0是中央控制器 1是蓝牙网关
      title: "设备详情（蓝牙网关）",
      form: {
        date: ""
      },
      detialsList: [
        {
          label: "设备类型",
          key: "type",
          value: ""
        },
        {
          label: "设备名称",
          key: "name",
          value: ""
        },
        {
          label: "设备编号",
          key: "sn",
          value: ""
        },
        {
          label: "设备状态",
          key: "status",
          value: ""
        },
        {
          label: "房源名称",
          key: "apartmentName",
          value: ""
        },
        {
          label: "关联设备(个)",
          key: "deviceCount",
          value: ""
        },
        {
          label: "安装位置",
          key: "location",
          value: "",
          isRow: true
        }
      ],
      opt: {
        title: "关联设备",
        columns: [
          { label: "设备类型", key: "typeStr" },
          { label: "设备编号/序列号", key: "sn" },
          { label: "设备名称", key: "name" },
          { label: "绑定时间", key: "createDate" },
          { label: "设备状态", key: "status" },
          {
            label: "开/关",
            key: "onOff",
            type: "switch",
            opt: {
              activeText: '开',
              inactiveText: '关',
              on: form => {
                return this.post(
                  "device-service/device/turnDevice",
                  {
                    id: form.id,
                    type: form.type,
                    sn: form.sn,
                    onOff: form.onOff ? "0" : "1"
                  },
                  {
                    isUseResponse: true
                  }
                )
                  .then(res => {
                    if (res.data.code == 0) {
                      return {
                        state: true
                      };
                    }
                  })
                  .catch(res => {
                    return {
                      state: false,
                      msg: res.data.msg
                    };
                  });
              }
            }
          }
        ]
      }
    };
  },
  activated() {
    console.log("ied-bluetooth created!!");
    if (this.$route.query.type == "蓝牙网关") {
      this.type = 1;
      this.title = "设备详情（蓝牙网关）";
    } else {
      this.type = 0;
      this.title = "设备详情（中央控制器）";
    }
    this.getDetail();
  },
  methods: {


    onGet(opt) {

       var dto = {
        gatewayId: this.$route.query.sn
      };
      this.post("device-service/device/queryDevicesByGateway", dto, {
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) { 
          let item = res.data.data[i];
          item.status = ["离线","在线"][item.status];
          item.typeStr = ["交流开关", "直流开关","智能灯"][item.type - 1];
          item.onOff = item.onOff == 1;
        }
        // 入参少分页、少total
        res.data.total = res.data.data.length;
        opt.cb(res.data);
      });
    },
    getDetail() {
      var dto = {
        id: this.$route.query.id
      };
      this.post( "device-service/gateway/getGateway",dto,{
         isUseResponse: true
        }
      ).then(res => {
        var data = res.data.data;
        data.type = ["中央控制器", "蓝牙网关"][data.type - 1];
        data.status = ["离线","在线"][data.status];
        
        this.detialsList.forEach(item => {
          item.value = data[item.key];
        })
     })
    }
  }
};
</script>
<style lang="scss">
.ied-bluetooth {
  .row-label {
    text-align: right;
  }

  .data-box {
    margin-bottom: 30px;
    max-width: 1200px;

    .data-table {
      color: #606266;
      border: solid 1px #e4e7ed;
      font-size: 14px;

      .row-label {
        background-color: #ebeef5;
      }
      .el-col {
        padding: 10px;
        height: 40px;
        line-height: 20px;
        border-left: solid 1px #e4e7ed;
      }

      .el-row {
        border-bottom: solid 1px #e4e7ed;
      }

      .el-col:nth-child(1),
      .el-row:nth-last-child(1) {
        border-bottom: none;
      }
    }

    // .data-title {
    //   margin-bottom: 20px;
    //   // border-bottom: solid 1px #e4e7ed;
    //   text-align: center;
    //   span {
    //     position: relative;
    //     padding: 0 10px;
    //     top: 10px;
    //     font-size: 15px;
    //     font-weight: bold;
    //     color: #909399;
    //     background-color: #ffffff;
    //   }
    // }
  }
}
</style>
