var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ied-bluetooth" },
    [
      _c("app-header", { attrs: { title: _vm.title, isShowBack: true } }),
      _c(
        "div",
        [
          _c("app-overview", {
            attrs: {
              title: _vm.type ? "// 蓝牙网关 //" : "// 中央控制器 //",
              list: _vm.detialsList
            }
          }),
          _c("app-list", { attrs: { opt: _vm.opt }, on: { get: _vm.onGet } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }